@import '../../global.scss';

.menu{
    width: 300px;
    height: 100vh;
    background-color: $sBlue;
    position: fixed;
    top: 0;
    right: -300px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 1s ease;

    &.active{
        right: 0;
    }

    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 40px;
        color: $white;
        font-weight: 300;
        width: 80%;

        li{
            margin-bottom: 25px;
            a{
                font-size: inherit;
                color: inherit;
                text-decoration: none;
            }

            &:hover{
                font-weight: 500;
            }
        }
    }
}