@import '../../global.scss';

.works {
    background-image: radial-gradient(circle, #db143d, #c40e35, #ae082d, #990325, #84001e);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .arrow{
        height: 50px;
        position: absolute;
        cursor: pointer;

        @include mobile {
            display: none;
        }

        &.left {
            left: 100px;
            transform: rotateY(180deg);
        }

        &.right {
            right: 100px;
        }
    }

    .slider{
        height: 500px;
        display: flex;
        position: absolute;
        left: 0px;
        transition: all 1s ease;

        @include mobile {
            height: 89vh;
            flex-direction: column;
            justify-content: center;
            
        }
        
        .container{
            width: 100vw;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;

            &.featured{
                
                @include mobile {
                    display: none;
                }
            }


            .item{
                width: 700px;
                height: 90%;
                background-color: $sBlue;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center; 
                box-shadow: 8px 8px 2px 1px $black;

                @include mobile {
                    width: 90%;
                    height: 165px;
                    margin: 15px 0;
                }
                
                .left  {
                    flex: 4;
                    height: 80%;
                    display: flex;
                    align-items: center;
                    justify-content: center; 

                    @include mobile {
                        height: 100%;
                    }

                    .leftContainer {
                        width: 90%;
                        height: 70%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                        @include mobile {
                            height: 90%;
                            padding-left: 10px;
                        }

                        .imgContainer{
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: #487094;
                            display: flex;
                            align-items: center;
                            justify-content: center; 

                            @include mobile {
                                margin-left: 25px;
                            }

                            img{
                                width: 25px;
                            }

                            
                        }

                        h2{
                            font-size: 20px;

                            @include mobile {
                                font-size: 12px;
                            }
                        }
                       .longDescription p{
                            font-size: 13px;

                            @include mobile {
                                display: none;
                            }
                        }

                        .shortDescription p{
                            display: none;

                            @include mobile {
                                display: flex;
                                font-size: 11px;
                            }
                        }

                        a{ 
                            text-decoration: none;
                            color: inherit;
                        }

                        span{
                            font-size: 12px;
                            font-weight: 600;
                            text-decoration: underline;
                            cursor: pointer;

                            &:hover {
                                color: $crimson;
                            }

                            // @include mobile {
                            //     display: none;
                            // }
                        }
                    }
                }

                .right {
                    flex: 8;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    img{
                        flex-shrink: 0.95;
                        min-width: 100%;
                        min-height: 100%;
                        transform: rotate(-10deg);
                    }
                }
            }
        }
    }
}