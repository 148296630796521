@import '../../global.scss';

.portfolio {
    background-color: $black;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1{
        font-size: 50px;
        color: $white;

        @include mobile{
            font-size: 20px;
        }
    }

    ul{
        margin: 20px;
        padding: 0;
        list-style: none;
        display: flex;
        color: $white;

        @include mobile {
            margin: 0;
            flex-wrap: wrap;
            justify-content: center;
            
        }

        li{
            font-size: 14px;
            margin-right: 50px;
            padding: 7px;
            border-radius: 10px;
            cursor: pointer;
            background-color: $pBlue;

            @include mobile {
                margin-top: 10px;
            }


            &.active{
                background-color: $crimson;
                color: $white;
            }
        }
    }

    .container{
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        @include mobile {
            width: 100%;
        }

        .item  {
            width: 220px;
            height: 180px;
            border-radius: 20px;
            box-shadow: 8px 8px 2px 1px $sBlue;
            margin: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            position: relative;
            transition: all 0.5s ease;
            cursor: pointer;

            @include mobile {
                width: 130px;
                height: 100px;
            }

            h3{
                position: absolute;
                font-size: 17px;

                @include mobile {
                    font-size: 15px;
                }
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: 1;
            }

            &:hover{
                background-color: $sBlue;
                img{
                    opacity: 0.2;
                    z-index: 0;
                }
            }
        }

    }
}