@import '../../global.scss';

.intro {
    background-color: $black;
    display: flex;

    @include mobile{
        flex-direction: column;
        align-items: center;
    }

    .left{
        flex: 0.5;
        overflow: hidden;

        .imgContainer{
            width: 700px;
            height: 700px;
            background-image: radial-gradient(circle, #db143d, #c40e35, #ae082d, #990325, #84001e);
            border-radius: 50%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            float: right;
            margin-top: 10px;
            box-shadow: 0 32px 16px -3px $sBlue;

            @include mobile{
                align-items: flex-start;
                width: 525px;
                height: 525px;
            }

            img{
                height: 80%;

                @include mobile{
                    height: 77%;
                }
            }
        }
    }

    .right{
        flex: 0.5;
        position: relative;

        .wrapper {
            width: 100%;
            height: 100%;
            padding-left: 50px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            color: $white;

            @include mobile {
                padding-left: 0;
                align-items: center;

            }

            h1{
                font-size: 60px;
                margin: 10px 0;

                @include mobile{
                    font-size: 40px;
                }
            }
            h2{
                font-size: 35px;

            }
            h3{
                font-size: 30px;
                
                @include mobile{
                    font-size: 20px;
                }

                span{
                    font-size: inherit;
                    color: $crimson;
                }

                .ityped-cursor {
                    animation: blink 1s infinite;
                }

                @keyframes blink {
                    50%{
                        opacity: 1;
                    }

                    100%{
                        opacity: 0;
                    }
                    
                }
            }

        }

        a{
            position: absolute;
            bottom: 10px;
            left: 40%;

            img{
                width: 30px;
                animation: arrowBlink 2s infinite;
            }

            @keyframes arrowBlink {
                100%{
                    opacity: 0;
                }
            }
        }
    }
}