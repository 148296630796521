

$crimson : #db143d;
$burgundy: #84001E;
$white : #F8F8F9;
$pBlue : #023059;
$sBlue : #1C1E32;
$black : #0d0d0d; 

$width: 768px;

@mixin mobile {
    @media (max-width: #{$width}){
        @content
    }
}