@import '../../global.scss';

.contact {
    background-color: #000000;
    display: flex;
    color: $white;

    @include mobile {
        flex-direction: column;
    }

    .left{
        flex: 1;
        overflow: hidden;

        img{
            margin-left: 50%;
            transform: translateX(-50%);
            height: 100%;
        }
    }
    .right{
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $sBlue;

        h2{
            font-size: 30px;
        }

        form{
            width: 70%;
            height: 70%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;

            input{
                width: 300px;
                height: 30px;
                font-size: 14px;

                @include mobile {
                    width: 200px;
                    height: 20px;
                }
                
            }

            textarea{
                width: 300px;
                height: 200px; 
                font-size: 14px;

                @include mobile {
                    width: 200px;
                    height: 100px;
                }
            }
            
            button{
                width: 150px;
                height: 30px;
                color: white;
                background-color: $crimson;
                border: none;
                border-radius: 10px;
                font-weight: 500;
                cursor: pointer;
                
                &:focus {
                    outline: none;
                }
            }
        }
    }
}