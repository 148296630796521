@import '../../global.scss';

.testimonials {
    background-color: $black;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @include mobile {
        justify-content: space-around;
    }

    h1{
        font-size: 50px;
        color: $white;

        @include mobile {
            font-size: 20px;
        }
    }

    .container{
        width: 100%;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile {
            flex-direction: column;
            height: 90%;
        }

        .card{
            width: 250px;
            background-color: $sBlue;
            height: 70%;
            border-radius: 10px;
            box-shadow: 0px 0px 15px -8px $crimson;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 20px;
            transition: all 1s ease;

            @include mobile {
                height: 180px;
                margin: 10px 0;
                box-shadow: none;
            }

            &.featured{
                width: 300px;
                height: 75%;
                margin: 0 30px;

                @include mobile {
                    width: 250px;
                    height: 180px;
                    margin: 1px;
                }
            }

            &:hover {
                transform: scale(1.1);
            }

            .top{
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                img{
                    &.left,&.right{
                        height: 25px;
                    }
                    &.user {
                        height: 60px;
                        width: 60px;
                        border-radius: 50%;
                        object-fit: cover;
                        margin: 0 30px;

                        @include mobile {
                            width: 30px;
                            height: 30px;
                        }
                    }

                }
            }

            .center1{
                padding: 10px;
                background-color: $pBlue;
                border-radius: 10px;
                color: $white;

                @include mobile {
                    display: none;
                }
            }

            .center2{
                display: none;

                @include mobile {
                    display: flex;
                    padding: 10px;
                    background-color: $pBlue;
                    border-radius: 10px;
                    color: $white;
                    font-size: 12px;
                    padding: 5px;
                }
            }

            .bottom{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                h3{
                    margin-bottom: 5px;
                    color: $crimson;

                    @include mobile {
                        font-size: 14px;
                    }
                }

                h4{
                    color: $white;
                    margin-bottom: 5px;

                    @include mobile {
                        font-size: 13px;
                    }
                }

                h5{
                    color: $burgundy;
                    font-size: 13px;
                }
            }
        }
    }
}