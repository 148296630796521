@import '../../global.scss';

form {

    .sendButton{
        width: 150px;
        height: 30px;
        color: white;
        background-color: $crimson;
        border: none;
        border-radius: 10px;
        font-weight: 500;
        cursor: pointer;
        
        &:focus {
            outline: none;
        }
    }
    
    span {
        color: green;
      }

}


